import React from 'react'
import styled from 'styled-components'
import StarRatings from 'react-star-ratings'
import ReviewPost from './ReviewPost'

const ReviewsContainer = styled.div`
text-decoration: none;
padding: 0 3%;

@media (max-width: 800px) {
  font-size: 95%;
}
@media (max-width: 330px){
  font-size: 90%;
}

h3, h4 {
  border: none;
}
.divider {
  margin-top 50px;
  border-bottom: 2px solid white;
}
.reviewList {
  padding: 0 15px;
}
.reviewItem {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  margin: 30px 0;
}
.reviewName {
  float: left;
}
.reviewDate {
  float: right;
}
.reviewRating {
  margin: 10px 0;
}
.writeReview{
  margin-top: 50px;
}
.captcha {
  margin-top: 10px;
}
.message-good {
  color: ${props => props.theme.plushColor};
  font-size: 20px;
  text-align: center;
}
.message-bad {
  color: ${props => props.theme.red};
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}
.reviewForm {
  box-sizing: border-box;
}
.star-ratings {
  margin-top: 10px;
}
.submit-button {
  cursor: pointer;
}
.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flexItem {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 20px;
}
.flexItem:first-child {
  margin-right: 25px;
  @media (max-width: 500px) {
    margin-right: 0;
  }
}
.field {
  padding: 15px;
  border: 1px solid white; 
  background: none;
  resize: vertical;
  border-radius: 3px;
  margin: 10px 0;
  color: white;
  @media (max-width: 500px) {
    min-width: 350px;
  }
  @media (max-width: 330px){
    min-width: 250px;
  }
}
button {
  background: white;
  width: 40%;
  padding: 15px;
  border-radius: 3px;
  color: ${props => props.theme.red};
  font-size: 18px;
  border: none;
  font-weight: bold;
  outline: none;
  margin-bottom: 50px;
  @media (max-width: 800px) {
    width: calc(50% - 25px);
  }
  @media (max-width: 500px) {
    width: 100%;
  }
}
button:hover {
  background: ${props => props.theme.red};
  color: white;
}
button:active {
  background: ${props => props.theme.redHover};
  color: white;
}
.red {
  color: ${props => props.theme.red};
}
`
const Reviews = ({reviews, productId}) => {
  return (
    <ReviewsContainer>
      <h3>What people say about Kooi</h3>
      <div className="reviewList">
        {reviews.map((review, i) => {
          let displayDate = review.date
          try {
            displayDate = review.date.split(' ')[0]
          } catch {}
          if (review.rating > 0) {
            return (
              <div className="reviewItem" key={i}>
                <div>
                  <h4 className="reviewName">{review.author}</h4>
                  <span className="reviewDate">{displayDate}</span>
                </div>
                <div className="reviewRating">
                  {review.rating > 0 && (
                    <StarRatings
                      name={`star-rating-review-${i}`}
                      rating={review.rating}
                      numberOfStars={5}
                      starDimension="25px"
                      starRatedColor="#F7A416"
                      starEmptyColor="#CFD5DB"
                      starHoverColor="#FBDAA2"
                    />
                  )}
                </div>
                <p>{review.content}</p>
              </div>
            )
          }
        })}
      </div>
      <div className="divider" />
      <ReviewPost productId={productId} />
    </ReviewsContainer>
  )
}

export default Reviews

/* eslint-disable */
import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import StarRatings from 'react-star-ratings'
import {analyticsSelect} from '../services/GoogleAnalytics'
import {pixelSelect} from '../services/FacebookPixel'
import SEO from '../components/SEO'
import ProductAttributes from '../components/ProductAttributes'
import AddToCart from '../components/AddToCart'
import ProductAddon from '../components/ProductAddon'
import MainProducts from '../components/MainProducts'
import Layout from '../components/Layout'
import styled from 'styled-components'
import {Link} from 'gatsby'
import ProductImagesCarousel from '../components/ProductImagesCarousel'
import {requestStock} from '../utils/WordPress'
import {slugify} from '../utils'

export const DROPDOWN_COUNT = 5

const ProductPageWrapper = styled.div`
  background: ${props => props.theme.grey4};
  .ui.orange.button {
    background: ${props => props.theme.red};
    color: ${props => props.theme.white};
    transition: all 300ms ease-in-out;
    &:hover {
      background: ${props => props.theme.redHover};
    }
  }
  .ui.compact.selection.dropdown {
    width: 100%;
  }
  .carousel.carousel-slider .control-arrow {
    padding: 10px;
  }
`
const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: repeat(2, auto) 1fr;
  grid-gap: 40px;
  grid-row-gap: 20px;
  align-items: flex-start;
  h2 {
    text-align: left;
    margin: 0 !important;
  }
  .ui.dropdown {
    .description {
      color: ${props => props.theme.dark} !important;
    }
  }
  .product-images {
    grid-row: 1 / span 3;
    .base-image {
      background: #fff;
      margin: 20px 0 20px 20px;
      border-radius: 5px;
      box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
      transition: all 300ms ease-in-out;
      max-width: 100%;
      line-height: 0;
      padding: 10px;
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      img {
        border-radius: 2px;
        max-width: 100%;
      }
    }
    .base-image:before {
      content: 'PLUS';
      font-weight: 600;
      font-size: 18px;
      position: absolute;
      top: -15px;
      left: calc(50% - 45px);
      background: ${props => props.theme.red};
      color: ${props => props.theme.white};
      width: 90px;
      height: 40px;
      border-radius: 50px;
      padding: 19px 23px;
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .product-name {
      grid-column: 1;
      grid-row: 1;
    }
    .product-detail {
      grid-column: 1;
      grid-row: 3;
    }
    .product-images {
      grid-column: 1;
      grid-row: 2;
      display: grid;
      grid-gap: ${props => (props.includeBase ? '20px' : '0')};
      grid-template-columns: repeat(
        ${props => (props.includeBase ? '2' : '1')},
        1fr
      );
      .base-image {
        margin: 0;
      }
      .base-image:before {
        bottom: 10px;
        top: auto;
        left: calc(0% - 45px);
        font-size: 16px;
        padding: 15px;
        width: 70px;
        height: 25px;
      }
    }
  }
`
const Breadcrumbs = styled.div`
  border-top: 1px solid #e7e7e7;
  display: block;
  padding: 8px 0;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 3px;
  span,
  a {
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.5);
    padding: 10px 10px 10px 12px;
    text-decoration: none;
    position: relative;
    &:first-child {
      padding-left: 0;
    }
    &:not(:first-child):before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid rgba(0, 0, 0, 0.3);
      opacity: 0.5;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      position: absolute;
      top: calc(50% - 5px);
      left: 0;
    }
  }
  a:hover {
    color: ${props => props.theme.grey1};
    text-decoration: underline;
  }
`

class ProductPageTemplate extends React.PureComponent {
  constructor(props) {
    super(props)
    this.childDiv = React.createRef()
    this.state = {
      selectedVariation:
        this.props.pageContext.product.product_variations.length > 2
          ? this.props.pageContext.product.product_variations.sort((a, b) =>
              Number(a.price) > Number(b.price) ? 1 : -1,
            )[0]
          : this.props.pageContext.product,
      variationsInStock: {},
      selectedBase: this.props.pageContext.baseProducts.sort((a, b) =>
        Number(a.price) > Number(b.price) ? 1 : -1,
      )[0],
      includeBase: false,
      stock: {},
      baseStockSet: false,
      mainProductStockSet: false,
    }
  }

  setSelectedVariation = variation => {
    this.setState({
      selectedVariation: variation,
    })
  }
  includeBaseHandler = data => {
    this.setState(() => {
      return {includeBase: data}
    })
  }
  selectedBaseHandler = data => {
    this.setState(() => {
      return {selectedBase: data}
    })
  }

  // On state update
  componentDidUpdate() {
    // Update base stock when user clicks "include base"
    if (
      !this.state.baseStockSet &&
      this.props.pageContext.product.categories.some(
        it => it.name === 'Mattresses',
      ) &&
      this.state.includeBase === true
    ) {
      // Check stock levels of each base type
      this.props.pageContext.baseProducts.forEach(baseParent => {
        // Query stock from WC
        requestStock(baseParent.wordpress_id).then(data => {
          Object.keys(data).forEach(id => {
            if (parseInt(id)) {
              this.setState(prevState => ({
                stock: {
                  ...prevState.stock,
                  [id]: data[id].manage_stock ? data[id].stock_quantity : 10,
                },
              }))
            }
          })
        })
      })
      // Upate the flag so we don't do this again
      this.setState({
        baseStockSet: true,
      })
    }
  }

  // After page has rendered
  componentDidMount() {
    // Check stock levels of this product
    const prodId = this.props.pageContext.product.wordpress_id
    // Query stock from WC
    requestStock(prodId).then(data => {
      Object.keys(data).forEach(id => {
        if (parseInt(id)) {
          this.setState(prevState => ({
            stock: {
              ...prevState.stock,
              [id]: data[id].manage_stock ? data[id].stock_quantity : 10,
            },
            mainProductStockSet: true,
          }))
        } else {
          this.setState(prevState => ({
            productInfo: {
              ...prevState.productInfo,
              [id]: data[id],
            },
          }))
        }
      })
    })

    // Check stock levels of promotional addon if applicable
    if (
      this.props.pageContext.product.categories.some(
        it => it.name === 'Mattresses',
      ) &&
      this.props.pageContext.sleepdotProduct
    ) {
      // Check stock levels of sleepdot from BC
      const sdProdId = this.props.pageContext.sleepdotProduct.wordpress_id
      requestStock(sdProdId).then(data => {
        Object.keys(data).forEach(id => {
          if (parseInt(id)) {
            this.setState(prevState => ({
              stock: {
                ...prevState.stock,
                [id]: data[id].manage_stock ? data[id].stock_quantity : 10,
              },
              mainProductStockSet: true,
            }))
          }
        })
      })
    }
    const categoryList = this.props.pageContext.product.categories.map(
      category => {
        category.name
      },
    )
    // Google analytics for landing on a product page
    analyticsSelect({
      sku: this.props.pageContext.product.sku,
      name: this.props.pageContext.product.name,
      price: this.props.pageContext.product.price,
      categories: this.props.pageContext.product.categories
        .filter(category => category.name !== 'All Products')
        .map(category => category.name)[0],
    })
    console.log(
      'productPage variation after load: ',
      this.state.selectedVariation,
    )
    // Facebook pixel for landing on a product page
    pixelSelect({
      sku: this.props.pageContext.product.sku,
      name: this.props.pageContext.product.name,
      price: this.props.pageContext.product.price,
      categories: this.props.pageContext.product.categories
        .filter(category => category.name !== 'All Products')
        .map(category => category.name)[0],
    })
  }
  render() {
    // Build the description and specs text
    let description =
      this.props.pageContext.product.description.split('{SPECS}')[0] || ''
    let specs =
      this.props.pageContext.product.description.split('{SPECS}')[1] || ''
    // Sort the images so they match the way they are set up in BC
    const productImagesSorted = this.props.pageContext.product.images.sort(
      (first, second) => first.name > second.name,
    )
    const baseImagesSorted =
      (this.state.selectedBase &&
        this.state.selectedBase.images.sort(
          (first, second) => first.name > second.name,
        )) ||
      []

    let sleepdotProduct = null
    if (
      this.props.pageContext.sleepdotProduct &&
      this.props.pageContext.product.categories.some(
        it => it.name === 'Mattresses',
      ) &&
      this.state.stock[this.props.pageContext.sleepdotProduct.wordpress_id]
    ) {
      sleepdotProduct = this.props.pageContext.sleepdotProduct
    }

    const productRating =
      parseFloat(
        this.state.productInfo && this.state.productInfo.review_average_rating,
      ) || 0
    return (
      <Layout location={this.props.location} flush>
        <SEO
          title={this.props.pageContext.product.name}
          imageSrc={productImagesSorted[0].src}
          htmldescription={this.props.pageContext.product.description}
          product={{
            name: this.props.pageContext.product.name,
            amount: (this.state.selectedVariation.price / 100).toFixed(2),
            currency: 'NZD',
          }}
          location={this.props.location}
        />
        <ProductPageWrapper>
          <Inner showBase={this.state.includeBase}>
            <div className="product-images">
              <ProductImagesCarousel
                images={productImagesSorted}
                name={this.props.pageContext.product.name}
              />
              {this.state.includeBase && (
                <div className="base-image">
                  <Link
                    to={`/product/${slugify(this.state.selectedBase.name)}/`}
                  >
                    <img
                      src={baseImagesSorted[0].src}
                      alt={this.props.pageContext.product.name}
                    />
                  </Link>
                </div>
              )}
            </div>
            <div className="product-name">
              <h2>{this.props.pageContext.product.name}</h2>
              {productRating > 0 && (
                <span>
                  <StarRatings
                    name="star-rating-home"
                    rating={productRating}
                    numberOfStars={5}
                    starDimension="25px"
                    starRatedColor="#F7A416"
                    starEmptyColor="#E7E7E7"
                    starHoverColor="#FBDAA2"
                  />
                </span>
              )}
              <Breadcrumbs>
                <Link to="/">Home</Link>
                <Link to="/store/">Store</Link>
                <span>{this.props.pageContext.product.name}</span>
              </Breadcrumbs>
            </div>
            <div className="product-detail">
              <AddToCart
                product={this.props.pageContext.product}
                stock={this.state.stock}
                selectedVariation={this.state.selectedVariation}
                selectedBase={this.state.selectedBase}
                setSelectedVariation={this.setSelectedVariation}
                baseProducts={this.props.pageContext.baseProducts}
                sleepdotProduct={sleepdotProduct}
                includeBaseCallback={this.includeBaseHandler}
                includeBase={this.state.includeBase}
                selectedBaseCallback={this.selectedBaseHandler}
                mainProductStockSet={this.state.mainProductStockSet}
              />
            </div>
            {sleepdotProduct && (
              <ProductAddon addon={this.props.pageContext.sleepdotProduct} />
            )}
          </Inner>
        </ProductPageWrapper>
        <ProductAttributes
          reviews={
            (this.state.productInfo && this.state.productInfo.reviews) || []
          }
          rating={productRating}
          productId={this.props.pageContext.product.wordpress_id}
          description={description}
          specs={specs}
          productName={this.props.pageContext.product.name}
        />
        <MainProducts title="Related" />
      </Layout>
    )
  }
}
export default ProductPageTemplate

import React from 'react'
import styled from 'styled-components'

const PromoItem = styled.div`
  background-color: #60c4e859;
  display: grid;
  grid-template-columns: 170px 1fr;
  align-items: center;
  justify-items: flex-start;
  grid-gap: 20px;
  .img {
    padding: 20px 0 20px 20px;
    img {
      box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
      border: 1px solid #e1e7ed;
      width: calc(100% - 20px);
    }
    line-height: 0;
  }
  .info {
    padding: 20px 20px 20px 0;
  }
  h4 {
    color: #162232;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    border: none;
  }
  .free {
    background-color: #60c4e8;
    color: #fff;
    font-size: 16px;
    padding: 3px 12px;
    text-transform: uppercase;
    border-radius: 3px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 150px 1fr;
    grid-gap: 0;
    .img {
      padding: 10px 0 10px 10px;
    }
    .free {
      display: block;
      padding: 8px;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 0;
  }
`

export default ({addon}) => {
  if (addon) {
    let descriptionText = ''
    // let descriptionText = addon.description
    // if (descriptionText.includes('{SPECS}')) {
    //   descriptionText = addon.description.split('{SPECS}')[0] || ''
    // }
    return (
      <PromoItem>
        <div className="img">
          <img src={addon.images[0].src} alt={addon.name} />
        </div>
        <div className="info">
          <span className="free">Free with this purchase!</span>
          <h4>{addon.name}</h4>
          <p>{descriptionText}</p>
        </div>
      </PromoItem>
    )
  }
  return null
}

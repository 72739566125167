import React from 'react'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'

import styled from 'styled-components'

const QuantityDropdownWrapper = styled.div`
  margin: 0 0;
  width: 100%;
`

export const DROPDOWN_COUNT = 5

const QuantityDropdown = ({
  quantity,
  setQuantity,
  stockLevel = 5,
  disabled = false,
}) => {
  const range =
    stockLevel > DROPDOWN_COUNT || stockLevel == 0 ? DROPDOWN_COUNT : stockLevel
  const quantityRange = Array.from(
    {
      length: range,
    },
    (x, i) => i + 1,
  )

  const quantityOptions = quantityRange.map(quant => {
    return {key: quant, text: quant, value: quant}
  })

  // Quantity change
  const handleQuantityChange = (event, data) => {
    setQuantity(parseInt(data.value))
  }

  return (
    <QuantityDropdownWrapper>
      <Dropdown
        placeholder="QTY"
        disabled={disabled}
        selection
        compact
        scrolling
        options={quantityOptions}
        value={quantity}
        onChange={handleQuantityChange}
      />
    </QuantityDropdownWrapper>
  )
}

export default QuantityDropdown

import React from 'react'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'

const CarouselWrapper = styled.div`
  background: #fff;
  margin: 0 0 40px 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
  transition: all 300ms ease-in-out;
  max-width: 100%;
  line-height: 0;
  position: relative;
  img {
    max-width: 100%;
  }
  .carousel .slide {
    background: #fff;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
  .carousel .control-dots {
    margin: 0;
    padding: 20px;
    .dot {
      box-shadow: none;
      border: 1px solid ${props => props.theme.grey2};
      opacity: 0.8;
      width: 10px;
      height: 10px;
      &.selected {
        border: 1px solid ${props => props.theme.red};
        background: ${props => props.theme.red};
      }
    }
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`

const ProductImagesCarousel = ({images, name}) => (
  <CarouselWrapper>
    <Carousel
      autoPlay
      showArrows
      emulateTouch
      stopOnHover
      useKeyboardArrows
      showStatus={false}
      showThumbs={false}
      interval={5000}
    >
      {images.map((image, i) => (
        <img key={i} src={image.src} alt={name} />
      ))}
    </Carousel>
  </CarouselWrapper>
)

export default ProductImagesCarousel

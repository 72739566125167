import React, {useState, useEffect} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'
import Checked from '../../images/icons/green-tick.svg'

const FeaturesWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    grid-gap: 10px;
    padding: 10px;
    margin: 2px 0;
    text-transform: capitalize;
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    li {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`

const ProductFeatures = ({product}) => {
  const data = useStaticQuery(graphql`
    {
      comparisons: wordpressWpMattress(slug: {eq: "comparison"}) {
        slug
        title
        acf {
          features {
            feature
            firm
            medium
            plush
          }
        }
      }
    }
  `)
  const {features} = data.comparisons.acf

  const [filter, setFilter] = useState('')

  useEffect(() => {
    if (product.includes('Pure Firm')) {
      setFilter('firm')
    }
    if (product.includes('Pure Medium')) {
      setFilter('medium')
    }
    if (product.includes('Pure Plush')) {
      setFilter('plush')
    }
  }, [product])

  return (
    <FeaturesWrapper>
      {features.map(
        (feature, ind) =>
          feature[filter] && (
            <li key={ind}>
              <img src={Checked} alt="checked icon" />
              <p>{feature.feature}</p>
            </li>
          ),
      )}
    </FeaturesWrapper>
  )
}
export default ProductFeatures

import React, {useState, useContext} from 'react'
import Reaptcha from 'reaptcha'
import StarRatings from 'react-star-ratings'
import AuthContext from '../Context/AuthContext'
import {postReview} from '../../utils/WordPress'
import EllipsisLoader from '../EllipsisLoader'
import {getUser} from '../../services/auth'

const ReviewPost = ({productId}) => {
  const {loggedInState, performLogout} = useContext(AuthContext)

  // Initialise states
  const [selectedRating, setSelectedRating] = useState(0)
  const [captchaPassed, setCaptchaPassed] = useState(false)
  const [author, setAuthor] = useState('')
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  // Clear all states
  const clearContent = () => {
    setAuthor('')
    setEmail('')
    setContent('')
    setSelectedRating(0)
  }

  // Hanlde review submission
  const handleSubmit = event => {
    event.preventDefault()
    // Field validation
    if (!author) {
      return setApiError('Please enter a valid name')
    }
    if (!loggedInState) {
      if (!email) {
        return setApiError('Please enter an email address')
      }
      if (!/\S+@\S+\.\S+/.test(email)) {
        return setApiError('Please enter a valid email address')
      }
    }
    if (!content) {
      return setApiError(
        'Please type a short comment of your experience using the product',
      )
    }
    if (!selectedRating) {
      return setApiError('Please pick a star rating for the product')
    }

    if (!loggedInState && !captchaPassed) {
      return setApiError(
        'Please complete the reCaptcha checkbox before continuing',
      )
    }

    if (author && content) {
      setApiError('')
      setSuccessMessage('')
      setLoading(true)

      const {token} = getUser()
      postReview(
        {
          id: productId,
          author,
          author_email: email,
          content,
          rating: selectedRating,
        },
        token,
        performLogout,
      )
        .then(res => {
          setLoading(false)
          clearContent()
          console.log('Review response: ', res)
          if (res && res.message) {
            setSuccessMessage(res.message)
          } else {
            setSuccessMessage(
              'Thank you for your review! Once it has been moderated, it will appear here',
            )
          }
        })
        .catch(async responseErr => {
          setCaptchaPassed(false)
          try {
            console.log(responseErr)
            if (responseErr.message) {
              setApiError(responseErr.message)
            } else {
              setApiError(
                'Oh no, something went wrong while submitting your review. Our team has been notified and will look into it ASAP',
              )
            }
          } catch (e) {
            setApiError(
              'Oh no, something went wrong while submitting your review. Our team has been notified and will look into it ASAP',
            )
          }
          setLoading(false)
        })
    }
  }

  // While loading, show loader
  if (loading) {
    return <EllipsisLoader height="500px" color="#d4d4d5" />
  }

  return (
    <div className="writeReview">
      {successMessage && <div className="message-good">{successMessage}</div>}
      {!successMessage && (
        <form className="reviewForm">
          <h4>Write a Review</h4>
          <p>Your email address will not be published, but your name will.</p>
          <div className="flexRow">
            <div className="flexItem">
              <label>
                Name<span className="red">*</span>
              </label>
              <input
                id="review_author"
                type="text"
                value={author}
                onChange={e => setAuthor(e.target.value)}
                className="field"
                required
              />
              <label>
                Email<span className="red">*</span>
              </label>
              <input
                id="review_author_email"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="field"
                required
              />
            </div>
            <div className="flexItem">
              <label>
                Your Rating<span className="red">*</span>
              </label>
              <StarRatings
                name="star-rating-user"
                rating={selectedRating}
                changeRating={rat => setSelectedRating(rat)}
                numberOfStars={5}
                starDimension="30px"
                starSpacing="7px"
                starRatedColor="#F7A416"
                starEmptyColor="#CFD5DB"
                starHoverColor="#FBDAA2"
              />
              <div className="captcha">
                <label>Please check the box before proceeding</label>
                <Reaptcha
                  sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}
                  onVerify={() => {
                    setCaptchaPassed(true)
                  }}
                  onExpire={() => {
                    setCaptchaPassed(false)
                  }}
                  onError={() => {
                    setCaptchaPassed(false)
                  }}
                  onLoad={() => {
                    setCaptchaPassed(false)
                  }}
                  size="normal"
                />
              </div>
            </div>
          </div>
          <div className="flexItem">
            <label>
              Comment<span className="red">*</span>
            </label>
            <textarea
              id="review_content"
              rows="5"
              value={content}
              onChange={e => setContent(e.target.value)}
              className="field"
              required
            />
          </div>
          {apiError && <div className="message-bad">{apiError}</div>}
          <button
            className="submit-button"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      )}
    </div>
  )
}

export default ReviewPost
